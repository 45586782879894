import ArrowDownIcon from '@icons/arrow/arrow-down.svg';
import ArrowUpIcon from '@icons/arrow/arrow-up.svg';
import ArrowRightIcon from '@icons/arrow/arrow-right.svg';
import CalendarArrowLeftIcon from '@icons/arrow/calendar-arrow-left.svg';
import CalendarArrowRightIcon from '@icons/arrow/calendar-arrow-right.svg';

export default {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowRightIcon,
  CalendarArrowLeftIcon,
  CalendarArrowRightIcon,
};
