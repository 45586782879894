import Crown1 from '@icons/4cut-ranking/crown-1.svg';
import Crown2 from '@icons/4cut-ranking/crown-2.svg';
import AddCard from '@icons/4cut-ranking/add-card.svg';
import GameConsole from '@icons/4cut-ranking/game-console.svg';
import UserGroup from '@icons/4cut-ranking/user-group.svg';
import Pin from '@icons/4cut-ranking/pin.svg';
import Gender from '@icons/4cut-ranking/gender.svg';
import ChevronDoubleRight from '@icons/4cut-ranking/chevron-double-right.svg';
import BronzeMedal from '@icons/4cut-ranking/achievement/medal-bronze.svg';
import SilverMedal from '@icons/4cut-ranking/achievement/medal-silver.svg';
import GoldMedal from '@icons/4cut-ranking/achievement/medal-gold.svg';
import PlatinumMedal from '@icons/4cut-ranking/achievement/medal-platinum.svg';
import PickMedal from '@icons/4cut-ranking/achievement/medal-pick.svg';
import MedalEffect from '@icons/4cut-ranking/achievement/medal-effect.svg';
import GameGuideArrow from '@icons/4cut-ranking/game-guide-arrow.svg';
import PickCursor from '@icons/4cut-ranking/pick-cursor.svg';
import CardLock from '@icons/4cut-ranking/card-lock.svg';
import CardHeart from '@icons/4cut-ranking/card-heart.svg';
import CardCrown from '@icons/4cut-ranking/card-crown.svg';
import CardCrownGradient from '@icons/4cut-ranking/card-crown-gradient.svg';
import HallOfFameTitleLeft from '@icons/4cut-ranking/hall-of-fame-title-left.svg';
import HallOfFameTitleRight from '@icons/4cut-ranking/hall-of-fame-title-right.svg';
import HallOfFameBronzeMedal from '@icons/4cut-ranking/achievement/hall-of-fame-medal-bronze.svg';
import HallOfFameSilverMedal from '@icons/4cut-ranking/achievement/hall-of-fame-medal-silver.svg';
import HallOfFameGoldMedal from '@icons/4cut-ranking/achievement/hall-of-fame-medal-gold.svg';
import RankingCrown1 from '@icons/4cut-ranking/ranking-crown-1.svg';
import RankingCrown2 from '@icons/4cut-ranking/ranking-crown-2.svg';
import RankingCrown3 from '@icons/4cut-ranking/ranking-crown-3.svg';
import ReportPhotoCardIcon from '@icons/4cut-ranking/report.svg';
import SearchIcon from '@icons/search.svg';
import SearchCancelIcon from '@icons/search-cancel.svg';

export default {
  Crown1,
  Crown2,
  AddCard,
  GameConsole,
  UserGroup,
  Pin,
  Gender,
  ChevronDoubleRight,
  BronzeMedal,
  SilverMedal,
  GoldMedal,
  PlatinumMedal,
  PickMedal,
  MedalEffect,
  GameGuideArrow,
  PickCursor,
  CardLock,
  CardHeart,
  CardCrown,
  CardCrownGradient,
  HallOfFameTitleLeft,
  HallOfFameTitleRight,
  HallOfFameBronzeMedal,
  HallOfFameSilverMedal,
  HallOfFameGoldMedal,
  RankingCrown1,
  RankingCrown2,
  RankingCrown3,
  ReportPhotoCardIcon,
  SearchIcon,
  SearchCancelIcon,
};
