import CloseIcon from '@icons/close.svg';
import EditIcon from '@icons/edit.svg';
import InfoIcon from '@icons/info.svg';
import InputClearIcon from '@icons/input-clear.svg';
import CircleCancelIcon from '@icons/circle-cancel.svg';
import ShareIcon from '@icons/share.svg';
import PlusInCircleIcon from '@icons/plus-in-circle.svg';
import ErrorIcon from '@icons/mobile/error-icon.svg';
import BackIcon from '@icons/header/back.svg';

export default {
  CloseIcon,
  EditIcon,
  InfoIcon,
  InputClearIcon,
  CircleCancelIcon,
  ShareIcon,
  PlusInCircleIcon,
  ErrorIcon,
  BackIcon,
};
